import React from 'react'
import styles from './styles.module.scss'
// import clsx from 'clsx'
import { DeleteIcon } from 'icons/index'
import clsx from 'clsx'

interface IBody {
  data: Array<{ email: string }>
  titles: string[]
  onUserPress: (email: string) => void
}

const Table: React.FC<IBody> = ({ data, titles, onUserPress }) => {
  return <div className={'flex flex-col w-full'}>
        <div className={clsx('flex flex-row w-full border-b pb-3 justify-between px-1 mt-8 mb-3', styles.headings)}>
            {titles.map((title, index) => <h2 key={index} className={styles.heading}>{title}</h2>)}
        </div>

            {data.map((user, index) => <div key={index} className={clsx('flex flex-row w-full mb-2 pb-3 border-b px-2 items-center gap-4 justify-between', [index !== 0 && 'pt-3'])}>
                <span key={index} className={styles.item}>{user.email}</span>

                <button onClick={() => { onUserPress(user.email) }} className={styles.delete}>
                    <DeleteIcon />
                    Delete
                </button>
            </div>)}
    </div>
}

export default Table
