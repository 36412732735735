import React, { type FC, useEffect } from 'react'
import styles from './styles.module.scss'
import { Dashboard } from 'components/index'
import { ExportWhite } from 'icons/index'
import type IUser from 'types/IUser'
import Table from 'pages/Users/components/Table'
import clsx from 'clsx'
import Pagination from 'components/Pagination'
import useMediaQuery from 'hooks/useMediaQuery'

const Users: FC = () => {
  const isDesktop = useMediaQuery('(max-width: 1920px)')
  const isLaptop = useMediaQuery('(max-width: 1440px)')
  const isTablet = useMediaQuery('(max-width: 768px)')
  const [containerHeight, setContainerHeight] = React.useState(0)
  const [numRows, setNumRows] = React.useState(0)
  const users: IUser[] = [
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Paid' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Paid' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Paid' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' },
    { username: 'Jawadur Rahman Jawad', email: 'jawad.akib12@gmail.com', createdAt: 'Nov 23, 2022', status: 'Free' }
  ]

  React.useEffect(() => {
    const handleResize = (): void => {
      const container: any = document.getElementById('table-container')
      const containerHeight = container.clientHeight
      setContainerHeight(containerHeight)
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial measurement
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  React.useEffect(() => {
    const rowHeight = 53
    const numRows = Math.floor(containerHeight / rowHeight)
    setNumRows(numRows)
  }, [containerHeight, isTablet, isLaptop, isDesktop])

  useEffect(() => {
    console.log(numRows)
  }, [numRows])

  return <Dashboard heading={'Users'} renderButton={<button className={styles.button}>
        <ExportWhite/>
        Export data
    </button>}>

        <div id={'table-container'} className={'h-full'}>
            <Table data={users} itemsToSlice={numRows}
                   titles={['name', 'registration date', 'Email', 'account status']}/>

            <div className={clsx(styles.footer)}>
                <Pagination/>
            </div>
        </div>
    </Dashboard>
}

export default Users
