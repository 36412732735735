import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'
import { Authorization, Settings, Users } from 'pages/index'

const App: React.FC = () => {
  return <BrowserRouter>
        <Routes>
            <Route path={'/'} element={ <Authorization/> }/>
            <Route path={'/users'} element={ <Users/> }/>
            <Route path={'/settings'} element={ <Settings/> }/>
        </Routes>
    </BrowserRouter>
}

export default App
