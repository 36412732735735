import React, { type FC } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { Checkmark } from 'icons/index'

interface Props {
  hasCreated: boolean
}

const Popup: FC<Props> = ({
  hasCreated
}) => {
  return <div className={clsx('w-full flex flex-row gap-6 justify-center items-center', styles.wrapper, {
    [styles.move]: (Boolean(hasCreated))
  })}>
        <Checkmark/>
        <p className={styles.subscription}>New Admin account successfully created</p>
    </div>
}

export default Popup
