import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import type IUser from 'types/IUser'

interface IBody {
  data: IUser[]
  // renderTableItem: (item: any, idx: number) => React.ReactNode
  titles: string[]
  itemsToSlice: number
}

const Table: React.FC<IBody> = ({ data, titles, itemsToSlice }) => {
  return <div className="overflow-y-hidden h-full overflow-x-hidden mt-8">
        <table className="w-full text-left">
            <thead className="uppercase align-top border-b">
            <tr>
                {titles.map((title, idx) => <th style={{ maxWidth: title === 'registration date' ? 108 : title === 'account status' ? 108 : 320 }} className={clsx('px-1 py-3', styles.heading)} key={idx}>{title === 'registration date' ? `Registration ${'\n'} date` : title}</th>)}
            </tr>
            </thead>
            <tbody>
            {data.slice(0, itemsToSlice).map((item, index) => <tr className={'gap-4 border-b'} key={index}>
                    <td
                        className={clsx('px-2 py-3 max-[768px]:py-2', styles.item)}>
                        {item.username}
                    </td>
                    <td className={clsx('px-2 py-3 max-[768px]:py-2 border-x', styles.item)}>
                        {item.createdAt}
                    </td>
                    <td className={clsx('px-2 py-3 max-[768px]:py-2 border-r', styles.item)}>
                        {item.email}
                    </td>
                    <td className={clsx('px-2 py-3 max-[768px]:py-2', styles.item)}>
                        {item.status}
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    </div>
}

export default Table
