import React, { type FC, useEffect, useState } from 'react'
import { Dashboard } from 'components/index'
import Table from 'pages/Settings/components/Table'
import Modal from 'pages/Settings/components/Modal'
import styles from './styles.module.scss'
import { AddUser } from 'icons/index'
import DeleteModal from 'pages/Settings/components/DeleteModal'

const Settings: FC = () => {
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [isDeleteUserModalActive, setIsDeleteUserModalActive] = useState(false)
  const [hasCreatedUser, setHasCreatedUser] = useState(false)
  const [user, setUser] = useState<{ email: string, password: string }>({
    email: '',
    password: ''
  })
  const [isPasswordSecured, setIsPasswordSecured] = useState(true)
  const users: Array<{ email: string }> = [
    { email: 'jawad.akib12@gmail.com' },
    { email: 'jawad.akib12@gmail.com' },
    { email: 'jawad.akib12@gmail.com' },
    { email: 'jawad.akib12@gmail.com' },
    { email: 'jawad.akib12@gmail.com' },
    { email: 'jawad.akib12@gmail.com' },
    { email: 'jawad.akib12@gmail.com' }
  ]

  const handleCleanupEmailInput = (): void => {
    setUser({ ...user, email: '' })
  }

  const handleTogglePasswordVisibility = (): void => {
    setIsPasswordSecured(prevState => !prevState)
  }

  const handleChangeUserCredentials = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const handleToggleModal = (): void => {
    setIsModalOpened(prevState => !prevState)
  }

  const handleSelectUser = (email: string): void => {
    setIsDeleteUserModalActive(prevState => !prevState)
    console.log(email)
  }

  const handleCreateUser = (): void => {
    setHasCreatedUser(prevState => !prevState)
    setIsModalOpened(prevState => !prevState)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasCreatedUser(false)
    }, 15 * 100)

    return () => { clearTimeout(timeout) }
  }, [(Boolean(hasCreatedUser))])

  const handleDeleteUser = (): void => {
    setIsDeleteUserModalActive(prevState => !prevState)
  }

  return <Dashboard heading={'Admins'} hasCreatedUser={hasCreatedUser} renderButton={<button onClick={handleToggleModal} className={styles.button}>
        <AddUser/>
        Add new user
    </button>}>
        <Table data={users} onUserPress={handleSelectUser} titles={['Email', 'Actions']}/>

        {isModalOpened && <Modal handleChangeUserCredentials={handleChangeUserCredentials}
                                 handleCleanupEmailInput={handleCleanupEmailInput}
                                 handleToggleModal={handleToggleModal}
                                 handleCreateUser={handleCreateUser}
                                 user={user}
                                 handleTogglePasswordVisibility={handleTogglePasswordVisibility}
                                 isPasswordSecured={isPasswordSecured}/>}

        {isDeleteUserModalActive &&
            <DeleteModal handleCancelPress={handleDeleteUser} handleDeletePress={handleDeleteUser}/>}
    </Dashboard>
}

export default Settings
