import React, { type FC } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

const Pagination: FC = () => {
  return <div className={styles.wrapper}>
        {[1, 2, 3].map(page => <div key={page} className={clsx(styles.pageWrapper, {
          [styles.pageWrapperActive]: page === 1
        })}>
          <span className={clsx(styles.pageValue, {
            [styles.pageValueActive]: page === 1
          })}>{page}</span>
        </div>)}
        <span>...</span>

      <div className={styles.pageWrapper}>
          <span className={styles.pageValue}>12</span>
      </div>
      <button className={styles.nextPageAction}>
          Next
      </button>
    </div>
}

export default Pagination
