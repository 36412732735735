// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__abjzV {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #141718;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/layout/Container/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,gBAAA;EACA,yBAAA;EAEA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAFF","sourcesContent":["@import 'constants/colors.scss';\n\n.wrapper {\n  width: 100%;\n  height: 100vh;\n  overflow: hidden;\n  background-color: #141718;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__abjzV`
};
export default ___CSS_LOADER_EXPORT___;
