// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_appLogo__eqRi8 {
  width: auto;
}

@media screen and (max-width: 1024px) {
  .styles_wrapper__tj--D {
    margin-left: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Sidedbar/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAGA;EACE;IACE,iBAAA;EAAF;AACF","sourcesContent":[".appLogo {\n  width: auto;\n}\n\n\n@media screen and (max-width: 1024px) {\n  .wrapper {\n    margin-left: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appLogo": `styles_appLogo__eqRi8`,
	"wrapper": `styles_wrapper__tj--D`
};
export default ___CSS_LOADER_EXPORT___;
