import React, { type FC } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { Button, InputWithIcon } from 'components/index'
import { CrossIcon, LockIcon, MessageIcon, VisibilityDisabled, VisibilityVisible } from 'icons/index'

interface Props {
  handleCleanupEmailInput: () => void
  handleTogglePasswordVisibility: () => void
  handleChangeUserCredentials: (e: React.ChangeEvent<HTMLInputElement>) => void
  isPasswordSecured: boolean
  user: { email: string, password: string }
  handleToggleModal: () => void
  handleCreateUser: () => void
}

const Modal: FC<Props> = ({
  handleChangeUserCredentials,
  handleTogglePasswordVisibility,
  handleToggleModal,
  handleCleanupEmailInput,
  handleCreateUser,
  isPasswordSecured,
  user
}) => {
  return <div
        className={clsx('w-full h-full absolute left-0 top-0 z-10 flex flex-col justify-center items-center', styles.overlay)}>
        <div className={clsx(styles.wrapper)}>
            <div className={clsx('relative', styles.padding)}>
                <button onClick={handleToggleModal} className={'absolute top-5 right-5'}>
                    <CrossIcon />
                </button>
                <h1 className={clsx('flex justify-center', styles.heading)}>Create new admin account</h1>

                <p className={clsx('mt-7', styles.subheading)}>Enter email and password for the new Admin account</p>

                <div className={clsx('w-full max-[768px]:mt-2 my-7 gap-4', styles.forms)}>
                    <InputWithIcon iconLeft={<MessageIcon/>}
                                   iconRight={<CrossIcon/>}
                                   name='email'
                                   value={user.email}
                                   onRightIconPress={handleCleanupEmailInput}
                                   onChange={handleChangeUserCredentials}
                                   maxLength={64} placeholder={'Enter your email'}
                    />

                    <InputWithIcon iconLeft={<LockIcon/>}
                                   iconRight={isPasswordSecured ? <VisibilityDisabled/> : <VisibilityVisible />}
                                   name='password'
                                   type={isPasswordSecured ? 'password' : 'text'}
                                   value={user.password}
                                   onRightIconPress={handleTogglePasswordVisibility}
                                   onChange={handleChangeUserCredentials}
                                   maxLength={64} placeholder={'Enter your password'}
                    />
                </div>
                <Button disabled={false} onClick={handleCreateUser} size={'full'}>Create account</Button>
            </div>
        </div>
    </div>
}

export default Modal
