import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

const Container: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className={clsx('flex items-center justify-center py-5 px-20', styles.wrapper)}>
      {children}
    </div>
}

export default Container
