// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__lvXwF {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.styles_pageWrapper__CZRba {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 44px;
  height: 44px;
  border-radius: 7px;
  background-color: #F2F4F8;
}
.styles_pageWrapperActive__pQGUK {
  background-color: #29B473;
}

.styles_pageValue__tZIAc {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  color: #313333;
}
.styles_pageValueActive__y75dt {
  color: #FFFFFF;
}

.styles_nextPageAction__7XQac {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 81px;
  height: 44px;
  background-color: #29B473;
  color: #FFFFFF;
  border-radius: 7px;
}`, "",{"version":3,"sources":["webpack://./src/components/Pagination/styles.module.scss","webpack://./src/constants/colors.scss","webpack://./src/constants/fonts.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;AADF;;AAIA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;AADF;AAGE;EACE,yBCrBG;ADoBP;;AAKA;EACE,gCEtBM;EFuBN,gBAAA;EACA,cCzBM;ADuBR;AAIE;EACE,cAAA;AAFJ;;AAMA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,yBC1CK;ED2CL,cAAA;EACA,kBAAA;AAHF","sourcesContent":["@import 'constants/colors.scss';\n@import 'constants/fonts.scss';\n\n.wrapper {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 10px;\n}\n\n.pageWrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  width: 44px;\n  height: 44px;\n  border-radius: 7px;\n  background-color: #F2F4F8;\n\n  &Active {\n    background-color: $main;\n  }\n}\n\n.pageValue {\n  font-family: $inter;\n  font-weight: 700;\n  color: $black;\n\n  &Active {\n    color: #FFFFFF;\n  }\n}\n\n.nextPageAction {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  width: 81px;\n  height: 44px;\n  background-color: $main;\n  color: #FFFFFF;\n  border-radius: 7px;\n}\n","$main: #29B473;\n$background-main: #FEFEFE;\n$gray: #F3F5F7;\n$black: #313333;\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__lvXwF`,
	"pageWrapper": `styles_pageWrapper__CZRba`,
	"pageWrapperActive": `styles_pageWrapperActive__pQGUK`,
	"pageValue": `styles_pageValue__tZIAc`,
	"pageValueActive": `styles_pageValueActive__y75dt`,
	"nextPageAction": `styles_nextPageAction__7XQac`
};
export default ___CSS_LOADER_EXPORT___;
