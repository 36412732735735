// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__eBgyD {
  visibility: hidden;
  height: 62px;
  background-color: #F1F5F9;
  border-radius: 12px;
  position: absolute;
  left: 0;
  top: 0;
}

.styles_subscription__TdwgS {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #000000;
}

.styles_checkmark__DVG78 {
  width: 23px;
  height: 20px;
}

.styles_wrapper__eBgyD.styles_move__hRBRI {
  visibility: visible;
  animation: styles_moveAnimation__FSesN 0.25s ease-in-out;
}

@keyframes styles_moveAnimation__FSesN {
  0% {
    height: 0;
    color: transparent;
  }
  100% {
    height: 62px;
    color: #000000;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Popup/styles.module.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,OAAA;EACA,MAAA;AADF;;AAIA;EACE,kCAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;AADF;;AAIA;EACE,WAAA;EACA,YAAA;AADF;;AAIA;EACE,mBAAA;EACA,wDAAA;AADF;;AAIA;EACE;IACE,SAAA;IACA,kBAAA;EADF;EAGA;IACE,YAAA;IACA,cAAA;EADF;AACF","sourcesContent":["@import 'constants/colors.scss';\n@import 'constants/fonts.scss';\n\n.wrapper {\n  visibility: hidden;\n  height: 62px;\n  background-color: #F1F5F9;\n  border-radius: 12px;\n  position: absolute;\n  left: 0;\n  top: 0;\n}\n\n.subscription {\n  font-family: 'Poppins', sans-serif;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 24px;\n  letter-spacing: -0.01em;\n  color: #000000;\n}\n\n.checkmark {\n  width: 23px;\n  height: 20px;\n}\n\n.wrapper.move {\n  visibility: visible;\n  animation: moveAnimation 0.25s ease-in-out;\n}\n\n@keyframes moveAnimation {\n  0% {\n    height: 0;\n    color: transparent;\n  }\n  100% {\n    height: 62px;\n    color: #000000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__eBgyD`,
	"subscription": `styles_subscription__TdwgS`,
	"checkmark": `styles_checkmark__DVG78`,
	"move": `styles_move__hRBRI`,
	"moveAnimation": `styles_moveAnimation__FSesN`
};
export default ___CSS_LOADER_EXPORT___;
