import React, { type FC } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

interface Props {
  handleDeletePress: () => void
  handleCancelPress: () => void
}

const DeleteModal: FC<Props> = ({
  handleDeletePress,
  handleCancelPress
}) => {
  return <div
      className={clsx('w-full h-full absolute left-0 top-0 z-50 flex flex-col justify-center items-center', styles.overlay)}>
      <div className={clsx(styles.wrapper)}>
          <div className={clsx('relative', styles.padding)}>
            <h1 className={styles.heading}>Are you sure you want to delete this Admin account?</h1>

            <div className={'w-full flex flex-row gap-4 justify-between items-center mt-8'}>
                <button className={styles.button} onClick={handleCancelPress}>Cancel</button>

                <button className={clsx(styles.button, styles.delete)} onClick={handleDeletePress}>Delete</button>
            </div>
          </div>
        </div>
    </div>
}

export default DeleteModal
