// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__0S3Ca {
  max-width: 528px;
  width: 100%;
  height: 52px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border: 2px solid #E7ECEF;
  background-color: transparent;
  border-radius: 12px;
  color: #313333;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
}
.styles_wrapper__0S3Ca:is(:active, :focus) {
  outline: none;
}

@media screen and (max-width: 460px) {
  .styles_wrapper__0S3Ca {
    width: 95%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/GoogleAuthButton/styles.module.scss","webpack://./src/constants/colors.scss","webpack://./src/constants/fonts.scss"],"names":[],"mappings":"AAGA;EACE,gBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EAEA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EAEA,yBAAA;EACA,6BAAA;EACA,mBAAA;EACA,cCfM;EDgBN,kCEjBQ;EFkBR,gBAAA;EACA,eAAA;AAHF;AAKE;EACE,aAAA;AAHJ;;AAOA;EACE;IACE,UAAA;EAJF;AACF","sourcesContent":["@import 'constants/colors.scss';\n@import 'constants/fonts.scss';\n\n.wrapper {\n  max-width: 528px;\n  width: 100%;\n  height: 52px;\n  cursor: pointer;\n\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  gap: 16px;\n\n  border: 2px solid #E7ECEF;\n  background-color: transparent;\n  border-radius: 12px;\n  color: $black;\n  font-family: $poppins;\n  font-weight: 600;\n  font-size: 16px;\n\n  &:is(:active, :focus) {\n    outline: none;\n  }\n}\n\n@media screen and (max-width: 460px) {\n  .wrapper {\n    width: 95%;\n  }\n}\n","$main: #29B473;\n$background-main: #FEFEFE;\n$gray: #F3F5F7;\n$black: #313333;\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__0S3Ca`
};
export default ___CSS_LOADER_EXPORT___;
