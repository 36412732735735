// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_overlay__fdusP {
  background-color: rgba(49, 51, 51, 0.6);
}

.styles_padding__Tl00O {
  padding: 3rem;
}

.styles_wrapper__yqjMs {
  max-width: 574px;
  min-width: 282px;
  width: 100%;
  height: 400px;
  background: linear-gradient(105.14deg, #FFFFFF 0.34%, #F9F9F9 48.48%), #F9F9F9;
  box-shadow: 0 1px 1px rgba(47, 48, 53, 0.1), 0 2px 6px rgba(47, 48, 53, 0.1);
  border-radius: 12px;
}

.styles_heading__4EEEG {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #313333;
  text-align: center;
}

.styles_subheading__rb36A {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #6C7275;
}

.styles_forms__oj6J3 {
  max-width: 478px;
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .styles_padding__Tl00O {
    padding: 3rem 2rem;
  }
  .styles_wrapper__yqjMs {
    max-width: 414px;
  }
  .styles_forms__oj6J3 {
    max-width: 345px;
  }
}
@media screen and (max-width: 768px) {
  .styles_padding__Tl00O {
    padding: 3rem 1rem;
  }
  .styles_wrapper__yqjMs {
    max-width: 282px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Settings/components/Modal/styles.module.scss","webpack://./src/constants/fonts.scss"],"names":[],"mappings":"AAGA;EACE,uCAAA;AADF;;AAIA;EACE,aAAA;AADF;;AAIA;EACE,gBAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;EAEA,8EAAA;EACA,4EAAA;EACA,mBAAA;AAFF;;AAKA;EACE,gCCnBM;EDoBN,eAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AAFF;;AAKA;EACE,gCC3BM;ED4BN,eAAA;EACA,gBAAA;EACA,cAAA;AAFF;;AAKA;EACE,gBAAA;EACA,WAAA;AAFF;;AAKA;EACE;IACE,kBAAA;EAFF;EAKA;IACE,gBAAA;EAHF;EAMA;IACE,gBAAA;EAJF;AACF;AAOA;EACE;IACE,kBAAA;EALF;EAQA;IACE,gBAAA;EANF;AACF","sourcesContent":["@import 'constants/colors.scss';\n@import 'constants/fonts.scss';\n\n.overlay {\n  background-color: rgba(49, 51, 51, 0.6);\n}\n\n.padding {\n  padding: 3rem;\n}\n\n.wrapper {\n  max-width: 574px;\n  min-width: 282px;\n  width: 100%;\n  height: 400px;\n\n  background: linear-gradient(105.14deg, #FFFFFF 0.34%, #F9F9F9 48.48%), #F9F9F9;\n  box-shadow: 0 1px 1px rgba(47, 48, 53, 0.1), 0 2px 6px rgba(47, 48, 53, 0.1);\n  border-radius: 12px;\n}\n\n.heading {\n  font-family: $inter;\n  font-size: 24px;\n  font-weight: 600;\n  color: #313333;\n  text-align: center;\n}\n\n.subheading {\n  font-family: $inter;\n  font-size: 12px;\n  font-weight: 400;\n  color: #6C7275;\n}\n\n.forms {\n  max-width: 478px;\n  width: 100%;\n}\n\n@media screen and (max-width: 1440px) {\n  .padding {\n    padding: 3rem 2rem;\n  }\n\n  .wrapper {\n    max-width: 414px;\n  }\n\n  .forms {\n    max-width: 345px;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .padding {\n    padding: 3rem 1rem;\n  }\n\n  .wrapper {\n    max-width: 282px;\n  }\n}\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `styles_overlay__fdusP`,
	"padding": `styles_padding__Tl00O`,
	"wrapper": `styles_wrapper__yqjMs`,
	"heading": `styles_heading__4EEEG`,
	"subheading": `styles_subheading__rb36A`,
	"forms": `styles_forms__oj6J3`
};
export default ___CSS_LOADER_EXPORT___;
