import React, { type FC } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { AppLogoBlack } from 'icons/index'
import { routes } from 'lib/routes'
import SidebarItem from 'components/Dashboard/Sidedbar/SidebarItem'
import useMediaQuery from 'hooks/useMediaQuery'

interface Route {
  label: string
  path: string
}

const Sidebar: FC = () => {
  const isTablet = useMediaQuery('(max-width: 1024px)')
  return <div className={styles.wrapper}>
        <figcaption className={styles.appLogo}>
            <AppLogoBlack width={isTablet ? 150 : 200}/>
        </figcaption>

        <div className={clsx('mt-14', styles.sidebarLinks)}>
            {routes.map((route: Route) => (<SidebarItem key={route.path} route={route}/>))}
        </div>
    </div>
}

export default Sidebar
