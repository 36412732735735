import React, { type ButtonHTMLAttributes } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { Loader } from 'icons/index'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled: any
  onClick: () => void
  size?: 'full' | 'half'
  loading?: boolean
}

const Button: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  loading,
  disabled, size,
  onClick
}) => {
  return <button onClick={onClick} disabled={disabled} style={{ maxWidth: size === 'full' ? 574 : 220 }}
                   className={clsx(styles.wrapper, {
                     [styles.disabled]: disabled
                   })}>
        {(loading === true) ? <Loader /> : children}
            </button>
}

export default Button
