import React, { type FC } from 'react'

interface Props {
  Icon: any
}

const SVG: FC<Props> = ({ Icon }) => {
  return <div>
      <Icon />
  </div>
}

export default SVG
