import React from 'react'
import { type PropsWithChildren } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import Sidebar from 'components/Dashboard/Sidedbar'
import Popup from 'components/Dashboard/Popup'
import useMediaQuery from 'hooks/useMediaQuery'
import { useNavigate } from 'react-router'

interface Props {
  heading: 'Users' | 'Admins'
  renderButton?: React.JSX.Element
  hasCreatedUser?: boolean
}

const Dashboard: React.FC<PropsWithChildren<Props>> = ({
  children,
  renderButton,
  heading,
  hasCreatedUser
}) => {
  const navigate = useNavigate()
  const isTablet = useMediaQuery('(max-width: 1024px)')

  const handleLogoutUser = (): void => {
    navigate('/')
  }

  return <div className={clsx('flex w-full h-screen flex-row space-between items-center py-6', styles.wrapper)}>
        <div className={clsx('w-full relative h-full flex items-center', styles.sidebar)}>
            <Sidebar/>

            <div className={'absolute max-[768px]:left-3 left-12 bottom-3'}>
                <button onClick={handleLogoutUser} className={styles.logout}>
                Log out
                </button>
            </div>
        </div>

        <div className={clsx('relative w-full flex-flex-col h-full mr-20', styles.dashboard)}>
            {(hasCreatedUser === true) && <Popup hasCreated={hasCreatedUser} />}
            <div className={clsx('flex flex-row justify-between items-center', styles.header)}>
                <h1 className={styles.heading}>{heading}</h1>
                {renderButton}
            </div>

            <div style={{ height: isTablet ? '88%' : '80%' }}>
                {children}
            </div>
        </div>
    </div>
}

export default Dashboard
