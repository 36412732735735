import React, { useState } from 'react'
import { Container } from 'layout'
import styles from './styles.module.scss'
import { AppLogo, CrossIcon, LockIcon, MessageIcon, VisibilityDisabled, VisibilityVisible } from 'icons/index'
import { Button, InputWithIcon } from 'components/index'
import { useNavigate } from 'react-router'
import clsx from 'clsx'
import useMediaQuery from 'hooks/useMediaQuery'

const Authorization: React.FC = () => {
  const navigate = useNavigate()
  const isTablet = useMediaQuery('(max-width: 1024px)')
  const isLaptop = useMediaQuery('(max-width: 1440px)')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [user, setUser] = useState<{ email: string, password: string }>({
    email: '',
    password: ''
  })
  const [isPasswordSecured, setIsPasswordSecured] = useState(true)
  const handleCleanupEmailInput = (): void => {
    setUser({ ...user, email: '' })
  }

  const handleTogglePasswordVisibility = (): void => {
    setIsPasswordSecured(prevState => !prevState)
  }

  const handleChangeUserCredentials = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const hasAbleToAuthorize = React.useCallback(() => {
    let isDisabled = true
    if (user.email.includes('@') && user.email.length >= 6 && user.password.length >= 6) {
      isDisabled = false
    }

    return isDisabled
  }, [user])

  const handleAuthorizeUser = (): () => void => {
    setIsLoading(prevState => !prevState)

    const timeout = setTimeout(() => {
      setIsLoading(false)
      navigate('/users')
    }, 15 * 100)

    return () => {
      clearTimeout(timeout)
    }
  }

  return <Container>
        <div className={clsx('w-full', styles.background)}>
            <AppLogo width={isTablet ? 171 : isLaptop ? 268 : 400} />
            <div className={'w-full flex justify-center items-center mt-8'}>
                <div className={clsx('w-full', styles.wrapper)}>
                    <InputWithIcon iconLeft={<MessageIcon/>}
                                   iconRight={<CrossIcon/>}
                                   name='email'
                                   value={user.email}
                                   onRightIconPress={handleCleanupEmailInput}
                                   onChange={handleChangeUserCredentials}
                                   maxLength={64} placeholder={'Enter your email'}
                    />

                    <InputWithIcon iconLeft={<LockIcon/>}
                                   iconRight={isPasswordSecured ? <VisibilityDisabled/> : <VisibilityVisible/>}
                                   name='password'
                                   type={isPasswordSecured ? 'password' : 'text'}
                                   value={user.password}
                                   onRightIconPress={handleTogglePasswordVisibility}
                                   onChange={handleChangeUserCredentials}
                                   maxLength={64} placeholder={'Enter your password'}
                    />

                    <div className={styles.footer}>
                        <Button size={'full'} onClick={handleAuthorizeUser} loading={isLoading}
                                disabled={hasAbleToAuthorize()}>
                            Sign in
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default Authorization
