// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__INd-e {
  max-width: 277px;
  width: 100%;
  height: 44px;
  background-color: #29B473;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FEFEFE;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  gap: 10px;
}

@media screen and (max-width: 1440px) {
  .styles_button__INd-e {
    max-width: 209px;
  }
}
@media screen and (max-width: 1024px) {
  .styles_button__INd-e {
    max-width: 201px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Settings/styles.module.scss","webpack://./src/constants/colors.scss","webpack://./src/constants/fonts.scss"],"names":[],"mappings":"AAGA;EACE,gBAAA;EACA,WAAA;EACA,YAAA;EACA,yBCPK;EDQL,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,kCEbQ;EFcR,gBAAA;EACA,SAAA;AADF;;AAIA;EACE;IACE,gBAAA;EADF;AACF;AAIA;EACE;IACE,gBAAA;EAFF;AACF","sourcesContent":["@import 'constants/fonts.scss';\n@import 'constants/colors.scss';\n\n.button {\n  max-width: 277px;\n  width: 100%;\n  height: 44px;\n  background-color: $main;\n  border-radius: 12px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  color: #FEFEFE;\n  font-size: 14px;\n  font-family: $poppins;\n  font-weight: 600;\n  gap: 10px;\n}\n\n@media screen and (max-width: 1440px) {\n  .button {\n    max-width: 209px;\n  }\n}\n\n@media screen and (max-width: 1024px) {\n  .button {\n    max-width: 201px;\n  }\n}\n\n","$main: #29B473;\n$background-main: #FEFEFE;\n$gray: #F3F5F7;\n$black: #313333;\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__INd-e`
};
export default ___CSS_LOADER_EXPORT___;
