// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__HtF0Y {
  width: 100%;
  height: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #29B473;
  border-radius: 12px;
  color: #FEFEFE;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.styles_disabled__GZrd\\+ {
  opacity: 0.58;
}

@media screen and (max-width: 460px) {
  .styles_wrapper__HtF0Y {
    width: 95%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Button/styles.module.scss","webpack://./src/constants/colors.scss","webpack://./src/constants/fonts.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,YAAA;EACA,eAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,yBCZK;EDaL,mBAAA;EACA,cCbgB;EDchB,kCEbQ;EFcR,gBAAA;AAHF;;AAMA;EACE,aAAA;AAHF;;AAMA;EACE;IACE,UAAA;EAHF;AACF","sourcesContent":["@import 'constants/colors.scss';\n@import 'constants/fonts.scss';\n\n.wrapper {\n  width: 100%;\n  height: 48px;\n  cursor: pointer;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  background-color: $main;\n  border-radius: 12px;\n  color: $background-main;\n  font-family: $poppins;\n  font-weight: 600;\n}\n\n.disabled {\n  opacity: 0.58;\n}\n\n@media screen and (max-width: 460px) {\n  .wrapper {\n    width: 95%;\n  }\n}\n","$main: #29B473;\n$background-main: #FEFEFE;\n$gray: #F3F5F7;\n$black: #313333;\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__HtF0Y`,
	"disabled": `styles_disabled__GZrd+`
};
export default ___CSS_LOADER_EXPORT___;
