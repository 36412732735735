// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_heading__3BuMj {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #313333;
}

.styles_item__9CFN5 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #313333;
  grid-column: 1/3;
  grid-row: 1;
}

@media screen and (max-width: 1440px) {
  .styles_heading__3BuMj {
    font-size: 14px;
  }
  .styles_item__9CFN5 {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .styles_heading__3BuMj {
    font-size: 10px;
  }
  .styles_item__9CFN5 {
    font-size: 10px;
  }
  .styles_moved__kdP52 {
    padding: 0 2rem;
  }
  table {
    border-spacing: 8px 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Users/components/Table/styles.module.scss","webpack://./src/constants/fonts.scss","webpack://./src/constants/colors.scss"],"names":[],"mappings":"AAGA;EACE,kCCFQ;EDGR,gBAAA;EACA,eAAA;EACA,cEJM;AFGR;;AAIA;EACE,gCCPM;EDQN,gBAAA;EACA,eAAA;EACA,cEXM;EFaN,gBAAA;EACA,WAAA;AAFF;;AAKA;EACE;IACE,eAAA;EAFF;EAKA;IACE,eAAA;EAHF;AACF;AAMA;EACE;IACE,eAAA;EAJF;EAOA;IACE,eAAA;EALF;EAQA;IACE,eAAA;EANF;EASA;IACE,qBAAA;EAPF;AACF","sourcesContent":["@import 'constants/colors.scss';\n@import 'constants/fonts.scss';\n\n.heading {\n  font-family: $poppins;\n  font-weight: 500;\n  font-size: 16px;\n  color: $black;\n}\n\n.item {\n  font-family: $inter;\n  font-weight: 500;\n  font-size: 16px;\n  color: $black;\n\n  grid-column: 1 / 3;\n  grid-row: 1;\n}\n\n@media screen and (max-width: 1440px) {\n  .heading {\n    font-size: 14px;\n  }\n\n  .item {\n    font-size: 12px;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .heading {\n    font-size: 10px;\n  }\n\n  .item {\n    font-size: 10px;\n  }\n\n  .moved {\n    padding: 0 2rem;\n  }\n\n  table {\n    border-spacing: 8px 0;\n  }\n}\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n","$main: #29B473;\n$background-main: #FEFEFE;\n$gray: #F3F5F7;\n$black: #313333;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `styles_heading__3BuMj`,
	"item": `styles_item__9CFN5`,
	"moved": `styles_moved__kdP52`
};
export default ___CSS_LOADER_EXPORT___;
