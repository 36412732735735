import React from 'react'
import styles from './styles.module.scss'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import { SVG } from 'components/index'

interface Props {
  route: {
    label: string
    path: string
    active: any
    disabled: any
  }
  icon: React.ReactNode
}

const SidebarItem: any = ({ route }: Props) => {
  const location = useLocation()
  const pathname = location.pathname
  return <NavLink className={styles.wrapper} to={route.path}>
        <div className={clsx(styles.indicator, {
          [styles.indicatorActive]: route.path === pathname
        })}/>

      <div className={'flex flex-row items-center'}>
        <SVG Icon={route.path === pathname ? route.active : route.disabled}/>
        <span className={clsx(styles.wrapperRoute, {
          [styles.wrapperRouteActive]: route.path === pathname
        })}>{route.label}</span>
      </div>
    </NavLink>
}

export default SidebarItem
