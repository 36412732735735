// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__i8dOT {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  margin-left: -4px;
}
.styles_wrapperRoute__0KzdK {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 16px;
  font-size: 16px;
  letter-spacing: 0.0041em;
  color: #BABABA;
  margin-left: 9px;
}
.styles_wrapperRouteActive__fv9I4 {
  color: #FFF;
}

.styles_indicator__YUucL {
  width: 4px;
  margin-right: 9px;
}
.styles_indicatorActive__HSsV2 {
  width: 4px;
  height: 32px;
  background-color: #29B473;
  margin-right: 9px;
}

@media screen and (max-width: 1440px) {
  .styles_wrapperRoute__0KzdK {
    font-size: 12px;
  }
}
@media screen and (max-width: 1024px) {
  .styles_wrapper__i8dOT {
    margin-left: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Sidedbar/SidebarItem/styles.module.scss","webpack://./src/constants/fonts.scss","webpack://./src/constants/colors.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;AADF;AAGE;EACE,kCCTM;EDUN,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,wBAAA;EACA,cAAA;EACA,gBAAA;AADJ;AAGI;EACE,WAAA;AADN;;AAMA;EACE,UAAA;EACA,iBAAA;AAHF;AAIE;EACE,UAAA;EACA,YAAA;EACA,yBE/BG;EFgCH,iBAAA;AAFJ;;AAMA;EACE;IACE,eAAA;EAHF;AACF;AAMA;EACE;IACE,cAAA;EAJF;AACF","sourcesContent":["@import 'constants/fonts.scss';\n@import 'constants/colors.scss';\n\n.wrapper {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  height: 56px;\n  margin-left: -4px;\n\n  &Route {\n    font-family: $poppins;\n    font-weight: 500;\n    line-height: 16px;\n    font-size: 16px;\n    letter-spacing: 0.0041em;\n    color: #BABABA;\n    margin-left: 9px;\n\n    &Active {\n      color: #FFF;\n    }\n  }\n}\n\n.indicator {\n  width: 4px;\n  margin-right: 9px;\n  &Active {\n    width: 4px;\n    height: 32px;\n    background-color: $main;\n    margin-right: 9px;\n  }\n}\n\n@media screen and (max-width: 1440px) {\n  .wrapperRoute {\n    font-size: 12px;\n  }\n}\n\n@media screen and (max-width: 1024px) {\n  .wrapper {\n    margin-left: 0;\n  }\n}\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n","$main: #29B473;\n$background-main: #FEFEFE;\n$gray: #F3F5F7;\n$black: #313333;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__i8dOT`,
	"wrapperRoute": `styles_wrapperRoute__0KzdK`,
	"wrapperRouteActive": `styles_wrapperRouteActive__fv9I4`,
	"indicator": `styles_indicator__YUucL`,
	"indicatorActive": `styles_indicatorActive__HSsV2`
};
export default ___CSS_LOADER_EXPORT___;
