import React, { type FC, type InputHTMLAttributes } from 'react'

import styles from './styles.module.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  onRightIconPress?: () => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  name: string
  value: string | undefined
  placeholder?: string
  type?: 'text' | 'password'

}

const InputWithIcon: FC<Props> = (
  {
    iconLeft,
    iconRight,
    onChange,
    name,
    type = 'text',
    placeholder,
    value,
    onRightIconPress
  }, rest) => {
  return <div className={styles.wrapper}>
      {iconLeft !== undefined && <div className={styles.leftIconWrapper}>
          {iconLeft}
      </div>}
        <input name={name} type={type} placeholder={placeholder} value={value} {...rest} onChange={onChange} className={styles.input}/>
      {iconRight !== undefined && <div onClick={onRightIconPress} className={styles.rightIconWrapper}>
          {iconRight}
      </div>}
    </div>
}

export default InputWithIcon
